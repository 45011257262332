import React from 'react'
import Layout from '../components/layout/Layout'
import SEO from '../components/generic/Seo'
import Teaser from '../components/artboxone/Teaser'
import { useStaticQuery, graphql , Link } from 'gatsby'
import { LandingPage, Artist } from '../types'
import { useTranslation } from 'react-i18next'
import { withUrlSlugs } from '../components/generic/withUrlSlugs'
import { mapInternalKey2Slug } from '../utils/UrlUtils'
import Edit from '../components/cms/Edit'
import {env} from '../../environment'
import { useArtistsData } from '../lib/artists_hook'

const Sitemap = (options: any) => {
  const artistsData = useArtistsData()
  const { t } = useTranslation('translation')

  const landingPages: any = {}

  const materials = [
    'poster',
    'poster_plastic',
    'poster_metal_frame',
    'poster_wooden_frame',
    'canvas',
    'alu',
    'acryl',
    'galleryprint',
    'wooden',
    'metalposter',
  ]

  artistsData
    .filter((artist: Artist) => {
      return artist.name != 'PX Ausstattung'
    })
    .map((artist: Artist) => {
      landingPages[artist.name] = []

      materials.map((key: string) => {
        landingPages[artist.name].push({
          url:
            '/wandbilder/' +
            mapInternalKey2Slug(key) +
            '/' +
            artist.slug +
            '/bestseller',
          title:
            t(key) +
            ' ' +
            t('von') +
            ' ' +
            artist.name,
          cockpitId: '',
        })
      })
    })

  if (artistsData.length == 0) {
    return <></>
  } else {
    return (
      <Layout>
        <SEO title="Die Künstler von artboxONE" />

        <div className="container">
          <Teaser body="Alle unsere Künstler" head="Künstler" />
          {Object.keys(landingPages).map((key: string) => (
            <>
              <h2>{key}</h2>
              <ul>
                {landingPages[key].map((page: LandingPage) => (
                  <li>
                    <Link to={page.url}>{page.title}</Link>
                  </li>
                ))}
              </ul>
            </>
          ))}
        </div>
      </Layout>
    )
  }
}

export default withUrlSlugs(Sitemap)
